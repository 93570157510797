import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'

export default function Sidebar() {
  return (
    <nav className='sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white' id='sidenav-main'>
      <div className='scrollbar-inner'>
        <div className='sidenav-header  align-items-center'>
          <a className='navbar-brand' href='javascript:void(0)'>
            <img src='../assets/img/logo.png' className='navbar-brand-img' alt='...' />
          </a>
        </div>
        <div className='navbar-inner'>
          <div className='collapse navbar-collapse' id='sidenav-collapse-main'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link className='nav-link active' to='/'>
                  <i className='ni ni-app text-primary' />
                  <span className='nav-link-text'>Dashboard</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/professionals'>
                  <i className='ni ni-hat-3 text-primary' />
                  <span className='nav-link-text'>Professional</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/business'>
                  <i className='ni ni-briefcase-24 text-primary' />
                  <span className='nav-link-text'>Business</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/businesscategory'>
                  <i className='ni ni-archive-2 text-primary' />
                  <span className='nav-link-text'>Business Categories</span>
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link' to='/rebate'>
                  <i className='ni ni-archive-2 text-primary' />
                  <span className='nav-link-text'>Rebate</span>
                </Link>
              </li> */}
              {/* <li className='nav-item'>
                <Link className='nav-link' to='/add-manage-profile'>
                  <i className='ni ni-tv-2 text-primary' />
                  <span className='nav-link-text'>Add Manage profile</span>
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link className='nav-link' to='/customer'>
                  <i className='ni ni-single-02 text-primary' />
                  <span className='nav-link-text'>Customer</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/orders'>
                  <i className='ni ni-box-2 text-primary' />
                  <span className='nav-link-text'>Orders</span>
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link' to='/orderdetails'>
                  <i className='ni ni-box-2 text-primary' />
                  <span className='nav-link-text'>Orders Detail</span>
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link className='nav-link' to='/reports'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Reports</span>
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link className='nav-link' to='/reportsdetail'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Reports Detail</span>
                </Link>
              </li> */}
              <li className='nav-item'>
                <Link className='nav-link' to='/adminuser'>
                  <i className='ni ni-circle-08 text-primary' />
                  <span className='nav-link-text'>Admin User</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/aboutus'>
                  <i className='ni ni-settings text-primary' />
                  <span className='nav-link-text'>Settings</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/subscription_list'>
                  <i className='ni ni-settings text-primary' />
                  <span className='nav-link-text'>Subscription List</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/advertisement'>
                  <i className='ni ni-notification-70 text-primary' />
                  <span className='nav-link-text'>ServiceHub Advertisement</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/advertise-subscription'>
                  <i className='ni ni-tv-2 text-primary' />
                  <span className='nav-link-text'>Advertisement Subscription</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/blogs'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Blogs</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/coupon-ranking'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Coupon Ranking Business</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/coupon-ranking-reports'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Coupon Ranking Reports</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/rebate'>
                  <i className='ni ni-single-copy-04 text-primary' />
                  <span className='nav-link-text'>Rebate</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}
