import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startrebateAction = () => {
  return {
    type: actionTypes.START_REBATE_ACTION
  };
};

export const rebateActionFail = message => {
  return {
    type: actionTypes.REBATE_ACTION_FAIL,
    message
  };
};

export const fetchrebateListSUCCESS = (rebateList,message = '') => {
  return {
    type: actionTypes.FETCH_REBATE_LIST_SUCCESS,
    listing: rebateList,
    message: message ? message : ''
  };
};

export const fetchrebateListAction = (message = '') => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/admin/get-rebate", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log("response.data", response.data)
            dispatch(fetchrebateListSUCCESS(response.data.data,message));
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};

export const addRebateAction = (data) => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/create-rebate", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchrebateListAction())
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};

export const rebateDeleteAction = (data) => {
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .delete("/admin/delete-rebate/"+data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchrebateListAction())
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};

export const updateRebateAction = (data) => {
  
  return dispatch => {
    dispatch(startrebateAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    
    axios
      .put("/admin/edit-rebate/"+data.categoryId, data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
           
            dispatch(fetchrebateListAction())
          } else {
            dispatch(rebateActionFail(response.data.message));
          }
        } else {
          dispatch(rebateActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(rebateActionFail(err.message));
      });
  };
};
