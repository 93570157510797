import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  rebatelisting: [],
};

const reducer = (state = initialState, action) => {
  console.log(action, "action")
   switch (action.type) {
    case actionTypes.START_REBATE_ACTION:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.REBATE_ACTION_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.FETCH_REBATE_LIST_SUCCESS :
        return {
            ...state,
            loading:null,
            rebatelisting:action.listing,
            error:null,
            success:action.message,
            
        }
    default:
      return state;
  }
};

export default reducer;
