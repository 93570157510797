import React from 'react'
import {
  BrowserRouter as Router,
  NavLink
} from 'react-router-dom'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import MUIDataTable from 'mui-datatables'
import { 
  Button , 
  Modal ,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup,
	Label,
	Input,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class Rebate extends React.Component {
  constructor(props){
    super(props)
    this.state={
      rebate : '',
      open:false,
      formErr:'',
      categoryId:'',
      openDialgue:false,
      formErrImage:'',
      file:'',
      category_images:'',
      location_required:'',
      formErrlocation_required:'',
      rideShare_delivery:'',
      formErrrideShare_delivery:'',
      short_name:'',
      formErrshort_name:'',


      city:"",
      month:"",
      year:"",
      avail_500:"",
      avail_200:"",
      avail_100:"",
      avail_50:"",
      avail_20:"",
      avail_5:"",
      claim_500:"",
      claim_200:"",
      claim_100:"",
      claim_50:"",
      claim_20:"",
      claim_5:"",
      remaining_claim_500:"",
      remaining_claim_200:"",
      remaining_claim_100:"",
      remaining_claim_50:"",
      remaining_claim_20:"",
      remaining_claim_5:"",


      formErrcity:"",
      formErrmonth:"",
      formErryear:"",
      formErravail_500:"",
      formErravail_200:"",
      formErravail_100:"",
      formErravail_50:"",
      formErravail_20:"",
      formErravail_5:"",
      formErrclaim_500:"",
      formErrclaim_200:"",
      formErrclaim_100:"",
      formErrclaim_50:"",
      formErrclaim_20:"",
      formErrclaim_5:"",
      formErrremaining_claim_500:"",
      formErrremaining_claim_200:"",
      formErrremaining_claim_100:"",
      formErrremaining_claim_50:"",
      formErrremaining_claim_20:"",
      formErrremaining_claim_5:""


    }

  }
  componentWillMount () {
    this.props.fetchRebate()
    console.log('this.props.listing')

    console.log(this.props)
  }
  onChangeAddName= async (key,value)=>{
    var self=this;
    await self.setState({
      [key]: value
    })
    if (key === "city") {
      await this.setState({ formErrcity: "" });
    } else if (key === "month") {
      await this.setState({ formErrmonth: "" });
    } else if (key === "year") {
      await this.setState({ formErryear: "" });
    } else if (key === "avail_500") {
      await this.setState({ formErravail_500: "" });
    } else if (key === "avail_200") {
      await this.setState({ formErravail_200: "" });
    } else if (key === "avail_100") {
      await this.setState({ formErravail_100: "" });
    } else if (key === "avail_50") {
      await this.setState({ formErravail_50: "" });
    } else if (key === "avail_20") {
      await this.setState({ formErravail_20: "" });
    } else if (key === "avail_5") {
      await this.setState({ formErravail_5: "" });
    } else if (key === "claim_500") {
      await this.setState({ formErrclaim_500: "" });
    } else if (key === "claim_200") {
      await this.setState({ formErrclaim_200: "" });
    } else if (key === "claim_100") {
      await this.setState({ formErrclaim_100: "" });
    } else if (key === "claim_50") {
      await this.setState({ formErrclaim_50: "" });
    } else if (key === "claim_20") {
      await this.setState({ formErrclaim_20: "" });
    } else if (key === "claim_5") {
      await this.setState({ formErrclaim_5: "" });
    } else if (key === "remaining_claim_500") {
      await this.setState({ formErrremaining_claim_500: "" });
    } else if (key === "remaining_claim_200") {
      await this.setState({ formErrremaining_claim_200: "" });
    } else if (key === "remaining_claim_100") {
      await this.setState({ formErrremaining_claim_100: "" });
    } else if (key === "remaining_claim_50") {
      await this.setState({ formErrremaining_claim_50: "" });
    } else if (key === "remaining_claim_20") {
      await this.setState({ formErrremaining_claim_20: "" });
    } else if (key === "remaining_claim_5") {
      await this.setState({ formErrremaining_claim_5: "" });
    }    
    console.log(self.state.rebate)
  }
  handleSubmit = async()=>{
    if (this.state.city === '') {
      await this.setState({ formErrcity: "Please provide a valid city" });
    } else if (this.state.month === '') {
      await this.setState({ formErrmonth: "Please provide a valid month" });
    } else if (this.state.year === '') {
      await this.setState({ formErryear: "Please provide a valid year" });
    } else if (this.state.avail_500 === '') {
      await this.setState({ formErravail_500: "Please provide a valid 500 amount" });
    } else if (this.state.avail_200 === '') {
      await this.setState({ formErravail_200: "Please provide a valid 200 amount" });
    } else if (this.state.avail_100 === '') {
      await this.setState({ formErravail_100: "Please provide a valid 100 amount" });
    } else if (this.state.avail_50 === '') {
      await this.setState({ formErravail_50: "Please provide a valid 50 amount" });
    } else if (this.state.avail_20 === '') {
      await this.setState({ formErravail_20: "Please provide a valid 20 amount" });
    } else if (this.state.avail_5 === '') {
      await this.setState({ formErravail_5: "Please provide a valid 5 amount" });
    } else if (this.state.claim_500 === '') {
      await this.setState({ formErrclaim_500: "Please provide a valid claim for 500" });
    } else if (this.state.claim_200 === '') {
      await this.setState({ formErrclaim_200: "Please provide a valid claim for 200" });
    } else if (this.state.claim_100 === '') {
      await this.setState({ formErrclaim_100: "Please provide a valid claim for 100" });
    } else if (this.state.claim_50 === '') {
      await this.setState({ formErrclaim_50: "Please provide a valid claim for 50" });
    } else if (this.state.claim_20 === '') {
      await this.setState({ formErrclaim_20: "Please provide a valid claim for 20" });
    } else if (this.state.claim_5 === '') {
      await this.setState({ formErrclaim_5: "Please provide a valid claim for 5" });
    } else if (this.state.remaining_claim_500 === '') {
      await this.setState({ formErrremaining_claim_500: "Please provide a valid remaining claim for 500" });
    } else if (this.state.remaining_claim_200 === '') {
      await this.setState({ formErrremaining_claim_200: "Please provide a valid remaining claim for 200" });
    } else if (this.state.remaining_claim_100 === '') {
      await this.setState({ formErrremaining_claim_100: "Please provide a valid remaining claim for 100" });
    } else if (this.state.remaining_claim_50 === '') {
      await this.setState({ formErrremaining_claim_50: "Please provide a valid remaining claim for 50" });
    } else if (this.state.remaining_claim_20 === '') {
      await this.setState({ formErrremaining_claim_20: "Please provide a valid remaining claim for 20" });
    } else if (this.state.remaining_claim_5 === '') {
      await this.setState({ formErrremaining_claim_5: "Please provide a valid remaining claim for 5" });
    }
    else {
      // console.log(this.state.categoryId);
      // return
      if(this.state.categoryId) {
        
        this.props.updateRebate({
          city:this.state.city,
          month:this.state.month,
          year:this.state.year,
          avail_500:this.state.avail_500,
          avail_200:this.state.avail_200,
          avail_100:this.state.avail_100,
          avail_50:this.state.avail_50,
          avail_20:this.state.avail_20,
          avail_5:this.state.avail_5,
          claim_500:this.state.claim_500,
          claim_200:this.state.claim_200,
          claim_100:this.state.claim_100,
          claim_50:this.state.claim_50,
          claim_20:this.state.claim_20,
          claim_5:this.state.claim_5,
          remaining_claim_500:this.state.remaining_claim_500,
          remaining_claim_200:this.state.remaining_claim_200,
          remaining_claim_100:this.state.remaining_claim_100,
          remaining_claim_50:this.state.remaining_claim_50,
          remaining_claim_20:this.state.remaining_claim_20,
          remaining_claim_5:this.state.remaining_claim_5,
          categoryId:this.state.categoryId,
        })
      } else {
        this.props.addRebate({
          city:this.state.city,
          month:this.state.month,
          year:this.state.year,
          avail_500:this.state.avail_500,
          avail_200:this.state.avail_200,
          avail_100:this.state.avail_100,
          avail_50:this.state.avail_50,
          avail_20:this.state.avail_20,
          avail_5:this.state.avail_5,
          claim_500:this.state.claim_500,
          claim_200:this.state.claim_200,
          claim_100:this.state.claim_100,
          claim_50:this.state.claim_50,
          claim_20:this.state.claim_20,
          claim_5:this.state.claim_5,
          remaining_claim_500:this.state.remaining_claim_500,
          remaining_claim_200:this.state.remaining_claim_200,
          remaining_claim_100:this.state.remaining_claim_100,
          remaining_claim_50:this.state.remaining_claim_50,
          remaining_claim_20:this.state.remaining_claim_20,
          remaining_claim_5:this.state.remaining_claim_5
        })
      }
      this.setState({
        open:false
      })
    }
  }
  handleOpen = (id,city, month, year, avail_500, avail_200, avail_100,
    avail_50, avail_20, avail_5, claim_500, claim_200, claim_100, 
    claim_50, claim_20, claim_5, remaining_claim_500, remaining_claim_200, 
    remaining_claim_100, remaining_claim_50, remaining_claim_20, remaining_claim_5 ) => {
    if(id !== ""){
      this.setState({
        city:city,
        month:month,
        year:year,
        avail_500:avail_500,
        avail_200:avail_200,
        avail_100:avail_100,
        avail_50:avail_50,
        avail_20:avail_20,
        avail_5:avail_5,
        claim_500:claim_500,
        claim_200:claim_200,
        claim_100:claim_100,
        claim_50:claim_50,
        claim_20:claim_20,
        claim_5:claim_5,
        remaining_claim_500:remaining_claim_500,
        remaining_claim_200:remaining_claim_200,
        remaining_claim_100:remaining_claim_100,
        remaining_claim_50:remaining_claim_50,
        remaining_claim_20:remaining_claim_20,
        remaining_claim_5:remaining_claim_5,
        categoryId:id
      })
    }else{
      this.setState({
        city:"",
        month:"",
        year:"",
        avail_500:"",
        avail_200:"",
        avail_100:"",
        avail_50:"",
        avail_20:"",
        avail_5:"",
        claim_500:"",
        claim_200:"",
        claim_100:"",
        claim_50:"",
        claim_20:"",
        claim_5:"",
        remaining_claim_500:"",
        remaining_claim_200:"",
        remaining_claim_100:"",
        remaining_claim_50:"",
        remaining_claim_20:"",
        remaining_claim_5:""
      })
    }
    this.setState({
      open:true 
    });
  
  }
  async close() {
    await this.setState({ 
      open: false,
      rebate:'',
      formErr:'',
      formErrImage:'',
      category_images:"",
      file:'',
      location_required:'',
      rideShare_delivery:'',
      short_name:'',
      city:"",
      month:"",
      year:"",
      avail_500:"",
      avail_200:"",
      avail_100:"",
      avail_50:"",
      avail_20:"",
      avail_5:"",
      claim_500:"",
      claim_200:"",
      claim_100:"",
      claim_50:"",
      claim_20:"",
      claim_5:"",
      remaining_claim_500:"",
      remaining_claim_200:"",
      remaining_claim_100:"",
      remaining_claim_50:"",
      remaining_claim_20:"",
      remaining_claim_5:""
    });
    // console.log(this.state.open) 
  }
  
  handleOpenDelete = (id) => {
    console.log(id)
		if(id) {

      this.setState({ 
        categoryId : id,
        openDialgue:true 
      });
      console.log(this.state.categoryId)
		}
	
	}
  open() {
    this.setState({ openDialgue: true });
  }
  closeDialgue() {
    this.setState({ openDialgue: false });
  }
  deleteUserPermanently() {
		if(this.state.categoryId) {
			this.props.rebateDelete(this.state.categoryId);
      this.closeDialgue()
      this.setState({ categoryId: '' });
		}
  }
  render () {
    // console.log(this.state.rideShare_delivery,'===========rideshare')
    const { open } = this.state;
    var sno = 1
    let categoryList = [];
    let businessCategoryTable = <Spinner />;
    {this.props.rebatelisting.length > 0
      ? this.props.rebatelisting.map(datas => {
        // console.log(datas,'======')
        categoryList.push(
          {

            s_no:(sno++),
            city:datas.city,
            month:datas.month,
            year:datas.year,
            avail_500:datas.avail_500,
            avail_200:datas.avail_200,
            avail_100:datas.avail_100,
            avail_50:datas.avail_50,
            avail_20:datas.avail_20,
            avail_5:datas.avail_5,
            claim_500:datas.claim_500,
            claim_200:datas.claim_200,
            claim_100:datas.claim_100,
            claim_50:datas.claim_50,

            claim_20:datas.claim_20,
            claim_5:datas.claim_5,
            remaining_claim_500:datas.remaining_claim_500,
            remaining_claim_200:datas.remaining_claim_200,
            remaining_claim_100:datas.remaining_claim_100,
            remaining_claim_50:datas.remaining_claim_50,
            remaining_claim_20:datas.remaining_claim_20,
            remaining_claim_5:datas.remaining_claim_5,


            action:<div>
              {/* <NavLink className='btn btn-icon btn-default btn-sm' to={'/businesssubcategory/'+datas._id+'/'+datas.name} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /></span>
              </NavLink> */}
              <Button className='btn btn-icon btn-info btn-sm' 
                onClick={(data_id,name) =>this.handleOpen(datas._id,datas.city, datas.month, datas.year, datas.avail_500, datas.avail_200, datas.avail_100,
                  datas.avail_50, datas.avail_20, datas.avail_5, datas.claim_500, datas.claim_200, datas.claim_100, 
                  datas.claim_50, datas.claim_20, datas.claim_5, datas.remaining_claim_500, datas.remaining_claim_200, 
                  datas.remaining_claim_100, datas.remaining_claim_50, datas.remaining_claim_20, datas.remaining_claim_5 )}
              >
                <span class='btn-inner--icon'><i class='fas fa-pencil-alt' /></span>
              </Button>
              {/* <button class='btn btn-icon btn-danger btn-sm' type='button'>
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </button> */}
              <Button className='btn btn-icon btn-danger btn-sm' 
              onClick={(id) =>this.handleOpenDelete(datas._id)}
              >
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </Button>
            </div>

            }
        )
      })

      : categoryList.push(
        ['No record']
      )}
      const data = {
        columns: [
          {
            label: "Sr.No",
            field: "s_no",
            sort: "asc",
          },
          {
            label: "city",
            field: "city",
            sort: "asc",
          },
          {
            label: "month",
            field: "month",
            sort: "asc",
          },
          
          {
            label: "year",
            field: "year",
            sort: "asc",
          },
          {
            label: "avail 500",
            field: "avail_500",
            sort: "asc",
          },
          {
            label: "avail 200",
            field: "avail_200",
            sort: "asc",
          },
          {
            label: "avail 100",
            field: "avail_100",
            sort: "asc",
          },
          {
            label: "avail 50",
            field: "avail_50",
            sort: "asc",
          },
          {
            label: "avail 20",
            field: "avail_20",
            sort: "asc",
          },
          {
            label: "avail 5",
            field: "avail_5",
            sort: "asc",
          },
          {
            label: "claim 500",
            field: "claim_500",
            sort: "asc",
          },
          {
            label: "claim 200",
            field: "claim_200",
            sort: "asc",
          },
          {
            label: "claim 100",
            field: "claim_100",
            sort: "asc",
          },
          {
            label: "claim 50",
            field: "claim_50",
            sort: "asc",
          },
          {
            label: "claim 20",
            field: "claim_20",
            sort: "asc",
          },
          {
            label: "claim 5",
            field: "claim_5",
            sort: "asc",
          },
          {
            label: "remaining claim 500",
            field: "remaining_claim_500",
            sort: "asc",
          },
          {
            label: "remaining claim 200",
            field: "remaining_claim_200",
            sort: "asc",
          },
          {
            label: "remaining claim 100",
            field: "remaining_claim_100",
            sort: "asc",
          },
          {
            label: "remaining claim 50",
            field: "remaining_claim_50",
            sort: "asc",
          },
          {
            label: "remaining claim 20",
            field: "remaining_claim_20",
            sort: "asc",
          },
          {
            label: "remaining claim 5",
            field: "remaining_claim_5",
            sort: "asc",
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
          },
        ],
        rows: categoryList,
      };
  
      businessCategoryTable = (
        <MDBDataTable striped bordered hover entries={10} data={data} />
      );
    const columns = ['Sr.No', 'Category Name', 'Action']
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Manage Rebate</h6>
                </div>
              </div>
                <Dialog
                  open={this.state.openDialgue}
                  onClose={() => this.closeDialgue()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Do you want to delete Rebate?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.closeDialgue()}>
                            No
                  </button>
                  <button class='btn btn-icon btn-success btn-sm' onClick={()=>this.deleteUserPermanently()} type='button'>
                            Yes
                  </button>
                </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <Button className='btn btn-primary float-right' 
                          onClick={(data_id,name) =>this.handleOpen("","","")}
                      >Add Rebate</Button>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {businessCategoryTable}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Modal
              isOpen={open}
              toggle={() => this.close()}
          >
              <ModalHeader toggle={() => this.close()}>
                <Label for="title">Add Rebate</Label>      
              </ModalHeader>
              <ModalBody>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="Enter Zip code" value={this.state.city} onChange={(e)=>this.onChangeAddName('city',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrcity}</span>
                  </FormGroup>
                  <FormGroup>
                    <Input
                      type="select"
                      name="month"
                      id="month"
                      value={this.state.month}
                      onChange={(e) => this.onChangeAddName('month', e.target.value)}
                    >
                      <option value="">Select Month</option>
                      {Array.from({ length: 12 }, (_, i) => {
                        const monthValue = (i + 1).toString().padStart(2, '0'); // Formats 1 -> 01, 2 -> 02, etc.
                        return (
                          <option key={monthValue} value={monthValue}>
                            {monthValue}
                          </option>
                        );
                      })}
                    </Input>
                    <span style={{ color: 'red' }}>{this.state.formErrmonth}</span>
                  </FormGroup>


                  <FormGroup>
                      <Input
                        type="number"
                        name="year"
                        id="year"
                        placeholder="Year"
                        min="2025"
                        max="2100"
                        value={this.state.year}
                        onChange={(e) => this.onChangeAddName('year', e.target.value)}
                      />
                      <span style={{color:'red'}}>{this.state.formErryear}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 500" value={this.state.avail_500} onChange={(e)=>this.onChangeAddName('avail_500',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_500}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 200" value={this.state.avail_200} onChange={(e)=>this.onChangeAddName('avail_200',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_200}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 100" value={this.state.avail_100} onChange={(e)=>this.onChangeAddName('avail_100',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_100}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 50" value={this.state.avail_50} onChange={(e)=>this.onChangeAddName('avail_50',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_50}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 20" value={this.state.avail_20} onChange={(e)=>this.onChangeAddName('avail_20',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_20}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="avail 5" value={this.state.avail_5} onChange={(e)=>this.onChangeAddName('avail_5',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErravail_5}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 500" value={this.state.claim_500} onChange={(e)=>this.onChangeAddName('claim_500',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_500}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 200" value={this.state.claim_200} onChange={(e)=>this.onChangeAddName('claim_200',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_200}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 100" value={this.state.claim_100} onChange={(e)=>this.onChangeAddName('claim_100',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_100}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 100" value={this.state.claim_50} onChange={(e)=>this.onChangeAddName('claim_50',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_50}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 20" value={this.state.claim_20} onChange={(e)=>this.onChangeAddName('claim_20',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_20}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="claim 5" value={this.state.claim_5} onChange={(e)=>this.onChangeAddName('claim_5',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrclaim_5}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 500" value={this.state.remaining_claim_500} onChange={(e)=>this.onChangeAddName('remaining_claim_500',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_500}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 200" value={this.state.remaining_claim_200} onChange={(e)=>this.onChangeAddName('remaining_claim_200',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_200}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 100" value={this.state.remaining_claim_100} onChange={(e)=>this.onChangeAddName('remaining_claim_100',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_100}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 50" value={this.state.remaining_claim_50} onChange={(e)=>this.onChangeAddName('remaining_claim_50',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_50}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 20" value={this.state.remaining_claim_20} onChange={(e)=>this.onChangeAddName('remaining_claim_20',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_20}</span>
                  </FormGroup>

                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="remaining claim 5" value={this.state.remaining_claim_5} onChange={(e)=>this.onChangeAddName('remaining_claim_5',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErrremaining_claim_5}</span>
                  </FormGroup>
              </ModalBody>
              <ModalFooter>
                
                  <Button variant="raised" className="btn-success text-white mr-10"  onClick={(e)=>this.handleSubmit()}>{this.state.categoryId !== ''?"Update":"Save"}</Button>
                      <Button variant="raised" onClick={()=>this.close()} className="btn-danger text-white mr-10">Cancel</Button>
              </ModalFooter>
            </Modal>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    rebatelisting: state.rebate.rebatelisting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRebate: () => dispatch(actions.fetchrebateListAction()),
    addRebate: (data) => dispatch(actions.addRebateAction(data)),
    updateRebate: (data) => dispatch(actions.updateRebateAction(data)),
    rebateDelete: (data) =>dispatch(actions.rebateDeleteAction(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Rebate))
